import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { cities } from 'vibemap-constants/dist/helpers.js'
import { getDistance, sortLocations } from 'vibemap-constants/dist/map'

const citiesAsLocations = cities.map(city => {
  city.centerpoint = [city.location.longitude, city.location.latitude]
  return city
})

export const getLocation = () => {
  let location = null
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(position => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        const currentLocation = {
          latitude: latitude,
          longitude: longitude,
          fromUser: true,
          userRequested: true,
          centerpoint: [longitude, latitude],
        }

        location = currentLocation
      },
      (error) => {
        console.log('DEBUG: Error getting location', error)
        //FIXME: This causes and error; does it need to be set: set({ hasLocation: false })
      }
    )
  } else {
    console.log('DEBUG: Geolocation is not supported by this browser.')
  }
  return location
}

const GetLocation = ({
  updateParentLocation,
  tryLocation = false,
  ...props
}) => {
  const distanceThreshold = 10
  const [location, setLocation] = useState(null)

  useEffect(() => {
    //const endpoint = 'https://geolocation-db.com/json/'
    const key = "3e3c4b6992c924e4599f1abfcca18822"
    const endpoint = `https://api.ipstack.com/check`

    const getLocation = async () => {
      const ip = await axios.get(`${endpoint}?access_key=${key}`).catch(e => { console.log('error in getLocation ', e) })

      if (ip?.data && ip?.data?.latitude) {
        const { latitude, longitude, city, zip, region_name } = ip.data
        const location = {
          latitude: latitude,
          longitude: longitude,
          centerpoint: [longitude, latitude],
          fromUser: true,
          userRequested: true,
          city: city,
          postal: zip,
          state: region_name
        }

        let bestLocation = location

        const sortedCities = location
          ? sortLocations(citiesAsLocations, location)
          : citiesAsLocations

        // Since IP can be off, use the closest city, if found

        const nearest = sortedCities.find(city => city !== undefined)

        if (nearest) {
          const distance = getDistance(nearest?.centerpoint, location?.centerpoint)

          if (distance < distanceThreshold) {
            bestLocation = nearest.location
            bestLocation.fromUser = true
          }
        }

        //console.log('Got location from IP in context ', location)
        setLocation(bestLocation)
        // TODO: Also set city in the parent?
        updateParentLocation(bestLocation)

        return location
      } else {
        return null
      }
    }

    // Try to get the best location for the user
    if (tryLocation == true) {
      getLocation()
    }


  }, [])

  return (null)
}

export default GetLocation
