const circles_defaults = {
  'circle-color': '#5c0b79',
  'circle-radius': ["+", ['get', 'vibes_score'], 10],
  'circle-radius': 10,
  'circle-stroke-width': 1,
  'circle-stroke-color': '#DDDDDD'
}

const circles_light = {
  'circle-color': '#FFFFFF',
  'circle-radius': 2,
  'circle-stroke-width': 1,
  'circle-stroke-color': '#999999',
  'circle-stroke-opacity': 0.4,
  'circle-opacity': 0.5,
}


const heatmap_defaults = {
  "heatmap-opacity": 0.20,
  "heatmap-radius": [
    "interpolate",
    ["linear"],
    ["zoom"],
    0, 2,
    10, 30,
    14, 40,
    20, 80
  ],
  "heatmap-intensity": 0.7
}


const heatmap_scale_blue = [
  "interpolate",
  ["exponential", 1],
  ["heatmap-density"],
  0,
  "hsla(0, 0%, 0%, 0)",
  0.33,
  "hsl(227, 79%, 52%)",
  0.95,
  "hsl(179, 62%, 61%)",
  1,
  "hsl(58, 62%, 62%)"
]


const heatmap_scale_green = [
  "interpolate",
  ["exponential", 1],
  ["heatmap-density"],
  0,
  "hsla(0, 0%, 0%, 0)",
  0.33,
  "hsla(159, 66%, 34%, 0.73)",
  0.95,
  "hsl(157, 62%, 61%)",
  1,
  "hsl(81, 62%, 62%)"
]


const heatmap_pink = [
  "interpolate",
  ["linear"],
  ["heatmap-density"],
  0.1,
  "hsla(240, 80%, 94%, 0.2)",
  // Replaced by heatmap.fifth
  0.2,
  "hsla(125, 63%, 88%, 0.4)",
  // Replaced by heatmap.fourth
  0.4,
  "hsla(192, 84%, 80%, 0.4)",
  // Replaced by heatmap.third
  0.6,
  "hsla(274, 100%, 65%, 0.5)",
  // Replaced by heatmap.second
  0.95,
  "hsla(300, 100%, 50%, 0.6)",
  // Replaced by heatmap.first
  1.1,
  "hsla(42, 100%, 64%, 0.6)",
  /* The pink is too much?
  1.2,
  "hsla(42, 88%, 65%, 0.9)"
  */
]

const heatmap_neon = [
  "interpolate",
  ["linear"],
  ["heatmap-density"],
  0,
  "rgba(0, 0, 255, 0)",
  0.11,
  "hsl(225, 73%, 57%)",
  0.2,
  "hsl(156, 100%, 50%)",
  0.3,
  "cyan",
  0.99,
  "hsl(60, 100%, 50%)",
  1,
  "hsl(305, 92%, 55%)"
]

module.exports = {
  MAPBOX_TOKEN: typeof process !== 'undefined' && process?.env?.GATSBY_API_MAPBOX_TOKEN || 'pk.eyJ1Ijoic3RldmVwZXBwbGUiLCJhIjoiTmd4T0wyNCJ9.1-jWg2J5XmFfnBAhyrORmw',
  MAPBOX_STYLE: typeof process !== 'undefined' && process?.env?.GATSBY_API_MAPBOX_STYLE || 'mapbox://styles/stevepepple/cka8kdq0i1dvv1it9nj0l70xn/draft?optimize=true',
  MAPBOX_STYLE_GRAY: typeof process !== 'undefined' && process?.env?.GATSBY_API_MAPBOX_STYLE_GRAY || '',
  MAPBOX_STYLE_OUTDOORS: typeof process !== 'undefined' && process?.env?.GATSBY_API_MAPBOX_STYLE_OUTDOORS || '',
  MAPBOX_ZOOM_DEFAULT: 12,
  MAPBOX_ZOOM_DETAILS: 16,

  // TODO: Make these configurable from Wordpress
  mapbox_settings: {
    dragPan: true,
    dragRotate: true,
    scrollZoom: true,
    touchZoom: true,
    touchRotate: true,
    keyboard: true,
    doubleClickZoom: true,
    minZoom: 0,
    maxZoom: 20,
    minPitch: 0,
    maxPitch: 85
  },

  circles: {
    ...circles_defaults
  },

  circles_blue: {
    ...circles_defaults,
    'circle-color': '#3475BA'
  },

  circles_green: {
    ...circles_defaults,
    'circle-color': '#006400'
  },

  circles_pink: {
    ...circles_defaults,
    'circle-color': '#FF00FF'
  },

  circles_light: {
    'circle-color': '#FFFFFF',
    'circle-radius': 2,
    //'circle-radius': ['get', 'vibes_score'],
    'circle-stroke-width': 1,
    'circle-stroke-color': '#999999'
  },

  hidden_circles: {
    ...circles_light,
    //'visibility': 'none'
    'circle-opacity': 1
  },

  heatmap: {
    ...heatmap_defaults,
    "heatmap-color": heatmap_pink
  },

  heatmap_blue: {
    ...heatmap_defaults,
    "heatmap-color": heatmap_scale_blue
  },

  heatmap_green: {
    ...heatmap_defaults,
    "heatmap-color": heatmap_scale_green
  },

  heatmap_neon: {
    ...heatmap_defaults,
    "heatmap-color": heatmap_neon
  },

  heatmap_color_pink: heatmap_pink,

  heatmap_color_neon: heatmap_neon,

  marker_layout_2: {
    "icon-allow-overlap": true,
    "icon-image": "pin", // TODO: Make this a variable
    "icon-size": 0.6,
  },

  marker_paint: {
    "text-color": "#7D7C84",
    "icon-color": "#3475BA",
    "text-halo-color": "#FFFFFF",
    "text-halo-width": 1.2,
  },

  // IMPORTANT: This is not active
  marker_layout_icons: {
    // Icon Style
    "icon-image": ["to-string", ["get", "icon"]],
    "icon-allow-overlap": false,
    "icon-ignore-placement": false,
    "icon-padding": 0,
    "icon-optional": false,
    "icon-size": [
      "interpolate",
      ["linear"],
      ["zoom"],
      8,
      ["+", ["*", ["get", "average_score"], 0.8], 0.2],
      20,
      ["+", ["*", ["get", "average_score"], 1.2], 0.2]
    ],
    "symbol-sort-key": ["get", "score_combined"],
    /*
    "text-size": [
      "interpolate",
      ["linear"],
      ["zoom"],
      8, 4,
      14, 8,
      20, 12
    ],
    "text-offset": [0, -1.0],
    "text-padding": 1,
    "text-field": ["to-string", ["get", "short_name"]],
    //"text-anchor": "top",
    "text-allow-overlap": false,
    "text-ignore-placement": false,
    "text-line-height": 0.8,
    'text-justify': 'auto',
    'text-variable-anchor': ['top', 'bottom', 'right'],
    "text-font": [
      "Public Sans Regular",
      "Arial Unicode MS Regular"
    ],
    "text-max-width": 10,
    "text-radial-offset": 1.6,
    */
    'visibility': 'visible'
  },


  marker_layout: {
    // Icon Style
    "icon-allow-overlap": false,
    "icon-ignore-placement": false,
    "icon-image": ["to-string", ["get", "icon"]],
    "icon-padding": 0,
    "icon-size": [
      "interpolate",
      ["linear"],
      ["zoom"],
      8,
      ["+", ["*", ["get", "score_combined"], 0.6], 0.4],
      15,
      ["+", ["*", ["get", "score_combined"], 1.2], 0.4],
      20,
      ["+", ["*", ["get", "score_combined"], 2.2], 0.5]
    ],
    "symbol-sort-key": ["get", "score_combined"],
    "text-field": ["to-string", ["get", "short_name"]],
    "text-anchor": "bottom",
    "text-allow-overlap": false,
    "text-ignore-placement": false,
    'text-letter-spacing': -0.02,
    "text-line-height": 0.8,
    "text-justify": 'auto',
    "text-padding": 2,
    "text-size": [
      "interpolate",
      ["linear"],
      ["zoom"],
      8, 6,
      14, [
        "case", // Make photo markers bigger
        ["==", ['get', 'showImage'], true],
        13, 9
      ],
      20, 13
    ],
    "text-font": [
      "Public Sans Regular",
      "Arial Unicode MS Regular"
    ],
    "text-optional": true,
    "text-max-width": [
      "interpolate",
      ["linear"],
      ["zoom"],
      8, 10,
      18, 14,
    ],
    "text-radial-offset": [
      "case", // Give photo markers more
      ["==", ['get', 'showImage'], true],
      3.1, 2
    ],
    'visibility': 'visible'
  },

  marker_paint: {
    'text-color': '#7D7C84',
    'icon-color': '#3475BA',
    'text-halo-color': '#FFFFFF',
    'text-halo-width': 1.2
  },

  route_layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },

  route_paint: {
    'line-color': '#00CEC8',
    'line-width': 4,
    'line-opacity': 0.75
  },

  route_arrow_layout: {
    'symbol-placement': 'line',
    'text-field': '▶',
    'text-size': [
      "interpolate",
      ["linear"],
      ["zoom"],
      12, 18,
      22, 42
    ],
    'symbol-spacing': [
      "interpolate",
      ["linear"],
      ["zoom"],
      12, 40,
      22, 200
    ],
    'text-keep-upright': false
  },

  route_arrow_paint: {
    'text-color': '#00B459',
    'text-halo-color': 'hsl(55, 11%, 96%)',
    'text-halo-width': 2
  },

  transit_paint: {
    'line-color': '#64A70B',
    'line-width': 2.5,
    'line-opacity': 0.75
  },

}
