/* layouts/index.js */

import React from "react"

import "../styles/index.scss"
import '../components/header/header.scss'
import "../components/footer/footer.scss"

const Layout = ({ children }) => {
  //console.log('In layout !!!')
  return (
    <>
      {/*Header*/}

      {children}

      {/*Footer*/}
    </>
  )
}

export default Layout